import { disableExperimentalFragmentVariables } from 'graphql-tag';
import { useState, useEffect, useCallback, useMemo } from 'react';

interface Arguments {
  url?: string;
  active?: boolean;
  onPlaying?: (id: string) => void;
  playerId?: string;
  forcePlay?: boolean;
  onEnd?: () => void;
  setListenFlags?: Function;
  listenFlags?: any[];
}

const useAudioPlayer = ({
  url,
  active = true,
  onPlaying,
  playerId,
  forcePlay = false,
  onEnd,
  setListenFlags = () => {},
  listenFlags,
}: Arguments) => {
  const [duration, setDuration] = useState<number>();
  const [playing, setPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState<number>();
  const [volume, setVolume] = useState<number>(1);

  const audio = useMemo(() => {
    const newAudio = new Audio(url);
    if (url) {
      newAudio.preload = 'auto';
      newAudio.load();
    }
    return newAudio;
  }, [url]);

  const setTime = useCallback(
    (time: number) => {
      if (url && active) {
        audio.currentTime = time;
        audio.volume = volume;
        setCurrentTime(time);
      }
    },
    [url, active, audio.currentTime, audio.volume, volume]
  );

  const setCurrentVolume = useCallback(
    (currentVolume: number) => {
      if (url && active) {
        audio.volume = currentVolume;
        setVolume(currentVolume);
      }
    },
    [audio, url, active]
  );

  const updateListenStatus = (playing: boolean, url: string) => {
    const updatedListenFlags = listenFlags?.map((file) =>
      file.url === url ? { ...file, listen: true, disablePlaying: false } : { ...file, disablePlaying: !playing }
    );
    setListenFlags(updatedListenFlags);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // if not active, display the playing
    if (!active) {
      setPlaying(false);
      audio.pause();
    }
    if (forcePlay) {
      setPlaying(true);
      audio.play();
    }
    const handleEnd = () => {
      onEnd?.();
      setPlaying(false);
    };
    if (url) {
      const setAudioData = () => {
        setDuration(audio?.duration);
        setCurrentTime(audio?.currentTime);
      };

      const setAudioTime = () => setCurrentTime(audio?.currentTime);

      // DOM listeners: update React state on DOM events
      audio?.addEventListener('ended', handleEnd);
      audio?.addEventListener('loadeddata', setAudioData);
      audio?.addEventListener('timeupdate', setAudioTime);

      // effect cleanup
      return () => {
        // pause if there is an existing audio
        if (playing) {
          audio?.pause();
        }
        audio?.removeEventListener('ended', handleEnd);
        audio?.removeEventListener('loadeddata', setAudioData);
        audio?.removeEventListener('timeupdate', setAudioTime);
      };
    }
  }, [active, audio, playing, url, forcePlay, onEnd]);

  const togglePlaying = async () => {
    updateListenStatus(playing, url || '');
    if (playing) {
      audio.pause();
    } else {
      setIsLoading(true);
      try {
        await audio.play();
        if (playerId) {
          onPlaying?.(playerId);
        }
      } finally {
        setIsLoading(false);
      }
    }
    setPlaying(!playing);
  };

  return {
    duration,
    currentTime,
    playing: playing && active,
    togglePlaying,
    setTime,
    isLoading,
    currentVolume: volume,
    setCurrentVolume,
  };
};

export default useAudioPlayer;
